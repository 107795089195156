var render, staticRenderFns
import script from "./index.ts?vue&type=script&lang=js&external"
export * from "./index.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@2.1.2_vu_7ce51f4bfdde780c8d522f22bf4abe1a/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports